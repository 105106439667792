import React from 'react';
import styled, { css } from 'styled-components';

const StyledSpacer = styled.div`
  ${({ size }) =>
    css`
      height: var(--spacing-${size});
    `};
`;

export default function Spacer({ size }) {
  return <StyledSpacer size={size} />;
}
