import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/SEO';
import Section from '../components/Section';
import Spacer from '../components/Spacer';
import { theme } from '../../theme';

const ContentWrapper = styled.div`
  padding: var(--spacing-large) 0;
  max-width: 700px;
  margin: 0 auto;

  .body {
    line-height: 1.5;

    a {
      text-decoration: underline;
      display: inline-block;
    }
  }
`;

const Meta = styled.p`
  margin-bottom: var(--spacing-xsmall);
`;

const Title = styled.h1`
  font-size: 3.2rem;
  font-weight: 800;
  line-height: 1;
  margin-bottom: var(--spacing-small);

  @media (min-width: ${() => theme.breakPoints.small}) {
    white-space: pre-line;
  }

  @media (min-width: ${() => theme.breakPoints.large}) {
    font-size: 4rem;
    margin-bottom: var(--spacing-medium);
  }

  @media (min-width: ${() => theme.breakPoints.xlarge}) {
    font-size: 4.8rem;
  }
`;

const ImgWrapper = styled.div`
  margin-bottom: var(--spacing-medium);

  img {
    width: 100%;
  }
`;

export default function Template({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  return (
    <>
      <SEO title={frontmatter.title} />
      <Section>
        <Spacer size="xlarge" />
        <ContentWrapper>
          <Meta>{frontmatter.date}</Meta>
          <Title>{frontmatter.title}</Title>
          <ImgWrapper>
            <img src={`/${frontmatter.thumbnail}`} alt={frontmatter.title} />
          </ImgWrapper>
          <div className="body" dangerouslySetInnerHTML={{ __html: html }} />
          <Spacer size="large" />
        </ContentWrapper>
      </Section>{' '}
    </>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date
        slug
        title
        thumbnail
      }
    }
  }
`;
