export const theme = {
  colors: {
    primaryDark: "#132636",
    primaryLight: "#ffffff",
    secondaryLight: "#adbdcc",
    highlightAi: "#80aeff",
    highlightSd: "#09bab5",
    highlightCs: "#f47421",
    highlightAr: "#f4ba4f",
  },
  breakPoints: {
    small: "768px",
    medium: "1024px",
    large: "1366px",
    xlarge: "1920px",
    xxlarge: "2560px",
  },
};
