import React from 'react';
import styled, { css } from 'styled-components';
import { theme } from '../../theme';

const StyledSection = styled.section`
  width: 100%;
  padding: 0 1.6rem;

  @media (min-width: ${() => theme.breakPoints.small}) {
    padding: 0 3.2rem;
  }

  // light bg section
  ${({ lightBg }) =>
    lightBg &&
    css`
      background: ${theme.colors.primaryLight};
      color: ${theme.colors.primaryDark};
    `}

  // clipped section 
  ${({ clipped }) =>
    clipped &&
    css`
      clip-path: polygon(0 4vw, 100% 0, 100% calc(100% - 4vw), 0 100%);
      margin-top: -4vw;
    `}
`;

export default function Section({ children, lightBg, clipped, className }) {
  return (
    <StyledSection lightBg={lightBg} clipped={clipped} className={className}>
      {children}
    </StyledSection>
  );
}
